import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Features from "../container/features"
import HomePage from "../container/home"
import InfoPage from "../container/info"
import PowerPage from "../container/power"
import TermsPage from "../container/terms"
import featuresData from "../Data/features.json"
import productData from "../Data/product.json"
import Terms from "../Data/terms.json"

const homeData = {
  header:
    "​Grow faster, transform your business with intelligent ERP software with advanced analytics powered.",
  sub: "Industry-leading system modules that are designed to effortlessly manage the business processes from inception to completion.",
}

const termpage = {
  header: "Empower your teams with data",
  desc: "Tronstride integrated Analytics layer turns data into live visualizations and real-time metrics, instantly available on any device to power decision-making at every level across the organization.",
  data: Terms,
}

const IndexPage = () => (
  <Layout>
    <Seo 
      title="ERP | Enterprise resource planning"
      description="Enterprise resource planning (ERP) is a software system that helps you run your entire business, supporting automation and processes in finance, human resources, manufacturing, supply chain, services, procurement, and more"
    />
    <HomePage data={homeData} />
    <Features data={featuresData} heading="Features" />
    <InfoPage />
    <PowerPage />
    <Features data={productData} heading="Product categories" />
    <TermsPage data={termpage} />
  </Layout>
)

export default IndexPage

import * as React from "react"

const TermsPage = ({ data }) => {
  return (
    <div className="text-center px-custom py-custom">
      <span className="text-800 text-2xl lg:text-4xl font-bold">{data.header}</span>
      <div className="text-900 text-lg text-center my-4">{data.desc}</div>
      <div className="page">
        <div className="archive">
          {data.data.map(data => {
            return (
              <article
                className={`article shadow-2 ${data.bgColor}`}
                key={data.id}
              >
                <span className={`text-xl font-bold ${data.fontColor}`}> {data.head} </span>
                <br />
                <div className="text-color text-base pt-3">{data.desc}</div>
              </article>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default TermsPage

import * as React from "react"
import power from "../images/power.jpg"
const PowerPage = () => (
  <div className="grid grid-nogutter surface-0 text-800 px-custom py-custom">
    <div className="col-12 md:col-6 text-center md:text-left flex align-items-center ">
      <section>
        <span className="block text-3xl lg:text-5xl font-bold mb-3">
          BI & ANALYTICS – POWERED IN ERP
        </span>
        <div className="text-3xl lg:text-5xl text-yellow-500 font-bold mb-3">
          Accelerate your business with analytics
        </div>
        <p className="mt-0 mb-4 text-700 line-height-3 text-xl font-normal">
          Interpret and use real-time data to drive informed decision making
          across your business.
        </p>
      </section>
    </div>
    <div className="col-12 md:col-6 overflow-hidden">
      <img className="w-full h-full"  src={power} alt="power"></img>
    </div>
  </div>
)

export default PowerPage

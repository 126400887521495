import * as React from "react"

const HomePage = ({ data }) => {
  return (
    <div className="bgcolor grid grid-nogutter surface-0 text-800 px-custom py-head-custom">
      <div className="col-12 text-center">
          <div className="text-4xl lg:text-6xl text-50 font-bold mb-3">
            {data.header}
          </div>
          <p className="mt-0 text-200 line-height-3 text-xl font-medium">{data.sub}</p>
      </div>
    </div>
  )
}

export default HomePage

import React, { useState } from "react"
import information from "../Data/information.json"

function InfoPage() {
  const [tabDisplay, setTabDisplay] = useState(1)
  const [more, setMore] = useState("")

  return (
    <div className="info bgcolor-secondry px-custom py-custom">
      <div className="text-3xl lg:text-5xl font-bold text-center">
        <span className="text-900">One Product, </span>
        <span className="text-yellow-500">Many Solutions</span>
      </div>
      <div className="text-900 text-lg text-center my-4">
        Replace manual processes with intuitive software.
      </div>

      <div className="tabs">
        {information.map((info) => {
          return (
            <>
              <span
                key={info.id}
                className={`label text-2xl ${tabDisplay === info.id ? "border" : ""}`}
                role="button"
                onClick={() => setTabDisplay(info.id)}
                onKeyPress={() => setTabDisplay(info.id)}
                tabIndex={info.id}
              >
                {info.header}
              </span>
              <div
                className={`tab ${tabDisplay === info.id ? "block" : "hidden"}`}
                id={info.id}
              >
                <div className="text-900 text-lg py-3">
                  {info.desc}
                </div>
                  <div className="archive">
                    {info.details.map(card => {
                      return (
                        <article className="article shadow-2" key={card.id}>
                          <span className="shadow-2 bg-white inline-block border-round-md mb-3">
                            <i className={`${card.font} text-4xl text-yellow-500 p-2`}></i>
                          </span><br/>
                          <span className="text-lg font-bold"> {card.head} </span>
                          <p className="text-lg m-0 mt-2">
                            {card.less}
                            {more !== card.id && card.more ? (
                              <span className="text-base font-light text-primary cursor-pointer"
                                onClick={() => setMore(card.id)}
                                onKeyPress={() => setMore(card.id)}
                                role="button" tabIndex={card.id}>...more</span>
                               
                            ) : null}
                            {more === card.id ? (
                              <>
                                <p className="inline"> {card.more}</p>
                                <span className="text-base font-light text-primary cursor-pointer"
                                  onClick={() => setMore("")}
                                  onKeyPress={() => setMore("")}
                                  role="button" tabIndex={card.id}> less</span>
                              </>
                            ) : null}
                          </p>
                        </article>
                      )
                    })}
                  </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  )
}

export default InfoPage

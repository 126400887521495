import * as React from "react"

const Features = ({ data, heading }) => {
  return (
    <div className="surface-0 text-center px-custom py-custom">
      <div className="text-center mb-5">
        <span className="text-800 text-2xl lg:text-4xl font-bold">{heading}</span>
      </div>
      <div className="grid">
        {data.map(data => (
          <div className="xs:col-6 sm:col-4 md:col-3 lg:col mb-5">
            <span className="shadow-2 inline-block border-round-md">
              <i className={`${data.font} text-4xl text-yellow-500 p-2`}></i>
            </span>
            <div className="text-900 font-semibold text-lg mt-2">{data.head}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Features
